
import { Component } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import StackedForm from '@/mixins/StackedForm';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import { Category } from '@/interfaces/models/Category';
import { Article } from '@/interfaces/models/Article';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import BannerImageForm from '@/components/banner/BannerImageForm.vue';
import { Banner as BannerModel } from '@/interfaces/models/Banner';
import { Localization } from '@/interfaces/models/Localization';
import AvailableHourForm from '@/components/banner/form/AvailableHourForm.vue';
import { BannerType } from '@/enums/BannerType';

const foodcard = namespace('foodcard');
const banner = namespace('banner');
const venue = namespace('venue');

@Component({
  components: { BannerImageForm, VFormBuilder, VWrapper, AvailableHourForm },
})
export default class EditBanner extends mixins(Editable, StackedForm) {
  @venue.State('active') public venue!: Venue;
  @foodcard.State('categories') public categories!: Category[];
  @foodcard.State('articleNames') public articles!: Array<Partial<Article>>;
  @banner.State('active') public banner!: BannerModel;

  @foodcard.Action('setCategoryFilter') public setCategoryFilter!: any;
  @foodcard.Action('fetchCategories') public getCategories!: any;
  @foodcard.Action('fetchArticleNames') public getArticleNames: any;

  @banner.Action('store') public saveBanner!: any;
  @banner.Action('uploadBannerImage') public uploadBannerImage!: any;
  @banner.Action('update') public updateBanner!: any;
  @banner.Action('show') public getBanner!: any;
  @banner.Mutation('setActive') public setBanner!: any;

  public currentType = BannerType.ARTICLE;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any; reset: () => void; getData: () => string[] };
    imageForm: InstanceType<typeof BannerImageForm> & { form: any; getData: () => string[]; reset: () => void };
    availableHours: InstanceType<typeof AvailableHourForm> & { getData: () => any };
  };

  get serializeBanner() {
    const banner: BannerModel = { ...this.banner };

    const name: any = { de: '' };
    if (banner?.name) {
      Object.keys(banner.name).map((key: string) => {
        if (banner?.name[key as keyof Localization] && banner?.name[key as keyof Localization] !== '') {
          name[key as keyof Localization] = banner.name[key as keyof Localization];
        }
      });
      banner.name = { ...name };
    }

    return banner;
  }

  get items() {
    return [
      { name: 'name', type: InputType.Language, label: 'banner.form.name', rules: 'required' },
      {
        name: 'type',
        type: InputType.Select,
        label: 'banner.form.type',
        items: Object.values(BannerType),
        default: 'article',
        change: this.setCurrentType,
      },
      {
        name: 'linkedArticle',
        type: InputType.Select,
        label: 'banner.form.linkedArticle',
        items: this.articlesSelect,
        visible: this.currentType === BannerType.ARTICLE,
        rules: 'required',
      },
      {
        name: 'linkedCategory',
        type: InputType.Select,
        label: 'banner.form.linkedCategory',
        items: this.categoriesSelect,
        visible: this.currentType === BannerType.CATEGORY,
        rules: 'required',
      },
    ];
  }

  get categoriesSelect() {
    return this.categories.map((cat: Category) => {
      return {
        value: cat._id,
        text: this.$options.filters!.localized(cat.name),
      };
    });
  }

  get articlesSelect() {
    return this.articles.map((article: Partial<Article>) => {
      return {
        value: article._id,
        text: this.$options.filters!.localized(article.name),
      };
    });
  }

  public setCurrentType(type: BannerType) {
    this.currentType = type;
  }

  public async mounted() {
    this.setBanner({});
    if (!this.venue) {
      this.$startLoading('venue');
    }
    if (this.venue) {
      await this.fetch();
      if (this.editing) {
        await this.getBanner({ venueId: this.venue._id, id: this.id });
        this.setCurrentType(this.banner?.type);
      }
    }
  }

  public async fetch() {
    this.$startLoading('banners');
    this.$startLoading('articles');
    this.$startLoading('categories');
    this.setCategoryFilter({ venue: this.venue._id });
    await Promise.all([this.getCategories(), this.getArticleNames({ venue: this.venue._id })]);

    this.$stopAllLoading();
  }

  public async cancel() {
    this.$refs.form.setData({});
    await this.$router.push({ name: 'banner.index' });
  }

  public async storeBanner() {
    this.$startLoading('banner.create');
    let savedBanner: BannerModel = {} as BannerModel;
    Promise.all(await this.validate())
      .then(async (res: any) => {
        if (this.isValid(res)) {
          const data: Partial<BannerModel> = {
            ...this.$refs.form.getData(),
            ...this.$refs.availableHours.getData(),
          };

          if (data.type === BannerType.CATEGORY) {
            delete data.linkedArticle;
          }

          if (data.type === BannerType.ARTICLE) {
            delete data.linkedCategory;
          }

          const imageData = this.$refs.imageForm.getData();

          if (this.editing) {
            const name: Partial<Localization> = this.manuallyFormObjectOfName(
              this.banner.name,
              data.name as Localization,
            );
            data.image = imageData;
            data.name = name as Localization;
            savedBanner = await this.updateBanner({ venueId: this.venue._id, id: this.id, banner: data });
          } else {
            savedBanner = await this.saveBanner({ venueId: this.venue._id, banner: data });
          }

          let formData: FormData;
          if (imageData.de && imageData.de instanceof File) {
            formData = new FormData();
            formData.append('file', imageData.de);
            await this.uploadBannerImage({
              venueId: this.venue._id,
              id: savedBanner?._id,
              image: formData,
              locale: 'de',
            });
          }
          if (imageData.en && imageData.en instanceof File) {
            formData = new FormData();
            formData.append('file', imageData.en);
            await this.uploadBannerImage({
              venueId: this.venue._id,
              id: savedBanner._id,
              image: formData,
              locale: 'en',
            });
          }
          if (imageData.fr && imageData.fr instanceof File) {
            formData = new FormData();
            formData.append('file', imageData.fr);
            await this.uploadBannerImage({
              venueId: this.venue._id,
              id: savedBanner._id,
              image: formData,
              locale: 'fr',
            });
          }
          await this.$router.push({ name: 'banner.index' });
        }
      })
      .finally(() => {
        this.$stopLoading('banner.create');
      });
  }
  // TODO
  private manuallyFormObjectOfName(origName: Localization, name: Localization): Partial<Localization> {
    if (origName && name) {
      const origNameKeys: string[] = Object.keys(origName);
      const nameKeys: string[] = Object.keys(name);

      return origNameKeys
        .map((key: string) => {
          if (nameKeys.find((nameKey) => nameKey === key)) {
            return { [key]: name[key as keyof Localization] };
          } else {
            return { [key]: '' };
          }
        })
        .reduce((prev, curr) => ({ ...prev, ...curr }), {});
    }

    return name;
  }
}
