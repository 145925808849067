
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';

@Component({
  components: { VFormBuilder },
})
export default class BannerImageForm extends mixins(StackedForm) {
  get items() {
    return [
      { name: 'de', type: InputType.File, label: 'banner.form.imageDe', default: null, accept: 'image/*' },
      { name: 'en', type: InputType.File, label: 'banner.form.imageEn', default: null, accept: 'image/*' },
      { name: 'fr', type: InputType.File, label: 'banner.form.imageFr', default: null, accept: 'image/*' },
    ];
  }

  public getData() {
    const data: any = { ...this.$refs.form.form };
    const filesToRemove: string[] = this.$refs.form.getFilesToRemove();

    if (data?.de === null) {
      delete data.de;
    }
    if (data?.en === null) {
      delete data.en;
    }
    if (data?.fr === null) {
      delete data.fr;
    }
    for (let item of this.items) {
      if (!data.hasOwnProperty(item.name)) continue;
      // @ts-ignore
      if (!data[item.name] && this.initialValues[item.name]) {
        // @ts-ignore
        data[item.name] = this.initialValues[item.name];
      }
    }

    if (this.initialValues) {
      // @ts-ignore
      if (
        // @ts-ignore
        this.initialValues.de &&
        // @ts-ignore
        this.initialValues.de.thumb &&
        // @ts-ignore
        this.initialValues.de.thumb.length > 0 &&
        filesToRemove.indexOf('de') > -1
      ) {
        data.de = {
          thumb: '',
          medium: '',
          large: '',
          original: '',
        };
      }
      // @ts-ignore
      if (
        // @ts-ignore
        this.initialValues.fr &&
        // @ts-ignore
        this.initialValues.fr.thumb &&
        // @ts-ignore
        this.initialValues.fr.thumb.length > 0 &&
        filesToRemove.indexOf('fr') > -1
      ) {
        data.fr = {
          thumb: '',
          medium: '',
          large: '',
          original: '',
        };
      }

      // @ts-ignore
      if (
        // @ts-ignore
        this.initialValues.en &&
        // @ts-ignore
        this.initialValues.en.thumb &&
        // @ts-ignore
        this.initialValues.en.thumb.length > 0 &&
        filesToRemove.indexOf('en') > -1
      ) {
        data.en = {
          thumb: '',
          medium: '',
          large: '',
          original: '',
        };
      }
    }

    return { ...data };
  }
}
